.name-bg {
  font-family: "Monoton", sans-serif;
  font-size: 55rem;
  line-height: 90%;
  color: rgb(144, 195, 196);
  text-overflow: clip;
}
.name-bg.p-0 {
  text-overflow: clip;
}
.name-fg {
  position: absolute;
  font-family: "Patua One", serif;
}
.title {
  position: absolute;
  text-align: start;
  bottom: 100px;
  left: -93px;
  font-size: 2rem;
  color: white;
  transform: rotate(90deg);
  transform-origin: left 200%;
  white-space: nowrap;
}
.name {
  position: relative;
  font-size: 2em;
  top: -175px;
  left: 0px;
  color: white;
}
@media only screen and (max-width: 600px) {
  /* .name {
    font-size: 4rem;
  } */
}
