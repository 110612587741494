.root,
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: "Prompt", sans-serif;
}

.connect {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 0;
}
